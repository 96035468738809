import { PieChart } from 'react-minimal-pie-chart';

const PieChartComponent = (props) => {
    return (
        <PieChart
            lineWidth={50}
            segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
            animate
            label={({ dataEntry }) => `${dataEntry.value}`}
            labelPosition={70}
            labelStyle={{
                fill: '#fff',
                opacity: 0.75,
                pointerEvents: 'none',
                fontSize:"5px"
            }}
            data={props.items}
            style={{width:"100%", height:"100%"}}
            />
    )
}

export default PieChartComponent
