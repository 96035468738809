import { TextArea } from '@fluentui/react-northstar'
import ReactWordcloud from 'react-wordcloud';

const ContentComponent2 = (props) => {

    const textAreaStyle= {
        width:"700px", 
        height: "100px",
        fontColor:"#000"}
    
    const buildWordcloud = (entities) => {
        var words = []
        entities.keywords.forEach(kw => 
            {
                var word = {
                   text: kw,
                   value: Math.random() * 100
                }

                words.push(word);
            });
        
        entities.nerLocations.forEach(kw => 
            {
                var word = {
                    text: kw,
                    value: Math.random() * 100
                }

                words.push(word);
            });

        entities.nerPeople.forEach(kw => 
            {
                var word = {
                    text: kw,
                    value: Math.random() * 100
                }

                words.push(word);
            });
        
        entities.nerOrganisations.forEach(kw => 
            {
                var word = {
                    text: kw,
                    value: Math.random() * 100
                }

                words.push(word);
            });

        return words;
    }

    return (
        <div>
            <span>Summary:</span>
            <div>
                <TextArea placeholder={props.cluster.summary} style={textAreaStyle}/>
            </div>
            <span>Keywords:</span>
            <div>
                <TextArea placeholder={props.cluster.entites.keywords} style={textAreaStyle}/>
            </div>
            <span>Some of the mentioned Locations:</span>
            <div>
                <TextArea placeholder={props.cluster.entites.nerLocations} style={textAreaStyle}/>
            </div>
            <span>Some of the mentioned People:</span>
            <div>
                <TextArea placeholder={props.cluster.entites.nerPeople} style={textAreaStyle}/>
            </div>
            <span>Some of the mentioned Organisations:</span>
            <div>
                <TextArea placeholder={props.cluster.entites.nerOrganisations} style={textAreaStyle}/>
            </div>
            <span>Wordcloud:</span>
            <div style={{height:"300px", width:"700px"}}>
                <ReactWordcloud 
                    words={buildWordcloud(props.cluster.entites)}
                    size={[700, 300]}
                    options={{
                        rotations: 2,
                        rotationAngles: [0, -90],
                    }}
                />
            </div>

        </div>
    )
}

export default ContentComponent2
