import { Label, Layout} from '@fluentui/react-northstar'


const TitleComponent = (props) => {
    return (
        <Layout 
            key={"title" + props.index}
            start={
            <Label 
                icon={
                    <div style={{backgroundColor:props.itemColor}} className='dot'></div>
                } 
                iconPosition="start" 
                circular 
                content={props.cluster.title} />}
        />
    )
}

export default TitleComponent
