import LangIdContainer from './components/LangIdContainer';
import DocContainer from './components/DocContainer';
//import TopTopicsContainer from './components/TopTopicsContainer';
import NamedEntityRecognitionContainer from './components/NamedEntityRecognitionContainer';
//import ArticleSummarizationContainer from './components/ArticleSummarizationContainer';
//import SemanticSimilarityContainer from './components/SemanticSimilarityContainer';
import SentimentAnalysisContainer from './components/SentimentAnalysisContainer';
import TargetSentimentAnalysisContainer from  './components/TargetSentimentAnalysisContainer';
import TopTopics2Container from './components/TopTopics2Container';
import TargetDominanceContainer from './components/TargetDominanceAnalysisContainer';

import { Dropdown, Image, ItemLayout } from '@fluentui/react-northstar'
import { useState } from "react"
import PressReleaseContainer from './components/PressReleaseContainer';

const availabeApis = [
  'Sentiment Analysis',
  'Target Sentiment Analysis',
  'Target Dominance Analysis',
  'Named Entity Recognition',
  'Language Identification',
 // 'Top Topics',
  'Top Topics 2',
  'Press Release Detection'
 // 'Article Summarization',
 // 'Semantic Similarity'
]

const components = {
  'Sentiment Analysis': SentimentAnalysisContainer,
  'Target Sentiment Analysis': TargetSentimentAnalysisContainer,
  'Target Dominance Analysis': TargetDominanceContainer,
  'Named Entity Recognition': NamedEntityRecognitionContainer,
  'Language Identification': LangIdContainer, 
 // 'Top Topics': TopTopicsContainer,
  'Top Topics 2': TopTopics2Container,
  'Press Release Detection': PressReleaseContainer
 // 'Article Summarization': ArticleSummarizationContainer,
 // 'Semantic Similarity': SemanticSimilarityContainer
};

function App() {
  const [selectedApi, setSelectedApi] = useState('Default')
  const SelectedComponent = components[selectedApi];

  return (
    <div className='app-options'>
      <ItemLayout
        media={
          <Image className='nlp-image' src={process.env.PUBLIC_URL + "/nlp-image.svg"}/>
        }
        header="NAPI Application"
        as="h1"
      />
      <br/>
      <Dropdown
        items={availabeApis}
        placeholder="Please Select NLP API"
        checkable
        getA11ySelectionMessage={{
            onAdd: item => setSelectedApi(item),
        }}
      />     
      <br/>
      {SelectedComponent ? <SelectedComponent/> :<DocContainer/>}
    </div>
  );
}

export default App;
