import { useState } from "react"
import axios from 'axios'
import { Form, Flex, Label, FormButton, FormTextArea, Header, Text} from '@fluentui/react-northstar'
import LoadingOverlay from 'react-loading-overlay';

const SentimentAnalysisContainer = () => {
    const [article, setArticle] = useState('')
    const [resultSentiment, setResultSentiment] = useState('N/A')
    const [resultScore, setResultScore] = useState('N/A')
    const [isActive, setIsActive] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault();
        setIsActive(true);
        
        const postData = {
          "text": article
        }

        axios.post('https://sentiment-analysis-api-development.9d6318478c9e44febab1.westeurope.aksapp.io/sentiment/',
        postData,
        {
          headers: {
            'Authorization': 'e440174e-1801-45c9-9101-0363e5c65e35',
            'Content-Type': 'application/json',
        }})
        .then((response) => { 
          setResultSentiment(response.data.label);
          setResultScore(response.data.score);
          setIsActive(false);
        })
    }

    return (
      <LoadingOverlay
            active={isActive}
            spinner
            text='Loading...'
        >
      <div>
        <Form
          onSubmit={handleSubmit}
        >
          <FormTextArea
            label="Article Text:"
            name="articleText"
            id="article-text"
            required
            onChange={e => setArticle(e.target.value)}
            placeholder="Add article text here."
            style={{width:"500px", height:"250px"}}
          />
          <FormButton content="Submit" />
        </Form>
        <br />
        <Flex gap="gap.small" vAlign="center">
          <span>
            Sentiment result: <Label circular>{resultSentiment}</Label>
          </span>
          <span>
            Sentiment score: <Label circular>{resultScore}</Label>
          </span>
        </Flex>
        <br />
        <Header as="h4">Swagger is available 
            <a target="_blank" rel="noreferrer" href="https://sentiment-analysis-api-development.9d6318478c9e44febab1.westeurope.aksapp.io/docs">here</a></Header>
        <Text content="Sentiment Analysis API provides one end point - sentiment."/>
        <br />
        <Text content="It receives one parameter - the Article text."/>
        <br />
        <br />
        <Text content="Curl call example:"/>
        <br />
        <Text content="curl -X 'POST'"/>
        <br />
        <Text content=" 'http://127.0.0.1:8000/sentiment/'"/>
        <br />
        <Text content=" -H 'accept: application/json'"/>
        <br />
        <Text content=" -H 'Content-Type: application/json'"/>
        <br />
        <Text content=" -d '"/>
        <br />
        <Text content="   ’text’: ’This is a test’ "/>
        <br />
        <Text content=" '"/>
      </div>
      </LoadingOverlay>
    )
}

export default SentimentAnalysisContainer
