import { useState } from "react"
import axios from 'axios'
import { Form, Flex, FormButton, FormTextArea, Header, Text} from '@fluentui/react-northstar'
import LoadingOverlay from 'react-loading-overlay';

const NamedEntityRecognitionContainer = () => {
    const [article, setArticle] = useState('')
    const [resultOrg, setResultOrg] = useState('N/A')
    const [resultLoc, setResultLoc] = useState('N/A')
    const [resultPer, setResultPer] = useState('N/A')
    const [isActive, setIsActive] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault();
        setIsActive(true);
        
        const postData = {
          "text": article
        }

        axios.post('https://ner-api-development.9d6318478c9e44febab1.westeurope.aksapp.io/ner_tagger/', 
        postData,
        {
          headers: {
            'Authorization': 'e440174e-1801-45c9-9101-0363e5c65e35',
            'Content-Type': 'application/json',
        }})
        .then((response) => { 
          setResultOrg(response.data.tokens.org.join(","));
          setResultLoc(response.data.tokens.loc.join(","));
          setResultPer(response.data.tokens.per.join(","));
          setIsActive(false);
        })
    }

    return (
      <LoadingOverlay
            active={isActive}
            spinner
            text='Loading...'
        >
      <div>
        <Form
          onSubmit={handleSubmit}
        >
          <FormTextArea
            label="Article Text:"
            name="articleText"
            id="article-text"
            required
            onChange={e => setArticle(e.target.value)}
            style={{width:"500px", hight:"500px"}}
            defaultValue="Add article text here."
          />
          <FormButton content="Submit" />
        </Form>
        <br />
        <Flex gap="gap.small" vAlign="center">
        <span>
                <FormTextArea
                label="Organization:"
                name="organization"
                id="organization"
                disabled
                style={{width:"300px", height:"200px", color:"black"}}
                value={resultOrg}
              />
          </span>
          <span>
                <FormTextArea
                label="Location:"
                name="location"
                id="location"
                disabled
                style={{width:"300px", height:"200px", color:"black"}}
                value={resultLoc}
              />
          </span>
          <span>
                <FormTextArea
                label="Person:"
                name="person"
                id="person"
                disabled
                style={{width:"300px", height:"200px", color:"black"}}
                value={resultPer}
              />
          </span>
        </Flex>
        <br />
        <Header as="h4">Swagger is available 
            <a target="_blank" rel="noreferrer" href="https://ner-api-development.ee6e33d131444447be33.switzerlandnorth.aksapp.io/ner_tagger/docs">here</a></Header>
        <Text content="Named Entity Recognition API provides one end point - ner_tagger."/>
        <br />
        <Text content="It receives one parameter - Article text."/>
        <br />
        <br />
        <Text content="Curl call example:"/>
        <br />
        <Text content="curl -X 'POST'"/>
        <br />
        <Text content=" 'http://127.0.0.1:8000/ner_tagger/'"/>
        <br />
        <Text content=" -H 'accept: application/json'"/>
        <br />
        <Text content=" -H 'Content-Type: application/json'"/>
        <br />
        <Text content=" -d '"/>
        <br />
        <Text content="   ’text’: ’This is a test’ "/>
        <br />
        <Text content=" Currently in development mode'"/>
      </div>
      </LoadingOverlay>
    )
}

export default NamedEntityRecognitionContainer
