import { useState } from "react"
import axios from 'axios'
import { Form, Flex, Label, FormButton, FormTextArea, Header, Text, Button } from '@fluentui/react-northstar'
import LoadingOverlay from 'react-loading-overlay';
import { saveAs } from 'file-saver';
import { json2csvAsync } from 'json-2-csv';


const TargetDominanceContainer = () => {
  const [article, setArticle] = useState('')
  const [entity, setEntity] = useState('')
  const [resultDominance, setResultDominance] = useState('N/A')
  const [isActive, setIsActive] = useState(false)
  const [file, setFile] = useState()

  const [resultCsvFile, setResultCsvFile] = useState()
  const [downloadLoading, setDownloadLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsActive(true);

    const postData = {
      "text": article,
      "target": entity
    }

    console.log(entity)
    console.log(article)

  
      axios.post('https://target-dominance-api-development.9d6318478c9e44febab1.westeurope.aksapp.io/target_dominance/',
        postData,
        {
          headers: {
            'Authorization': 'e440174e-1801-45c9-9101-0363e5c65e35',
            'Content-Type': 'application/json',
          }
        })
        .then((response) => {
          console.log(response.data)
          setResultDominance(response.data[0]);
          setIsActive(false);
        })
    }
  

  const handleUploadFile = (e) => {
    e.preventDefault();
    setIsActive(true);
    const postData = new FormData()

    postData.append('file', file, file.name)
    axios.post('https://target-dominance-api-development.9d6318478c9e44febab1.westeurope.aksapp.io/target_dominance_csv_file/',
      postData,
      {
        headers: {
          'Authorization': 'e440174e-1801-45c9-9101-0363e5c65e35',
          'Content-Type': 'multipart/form-data',
        }
      })
      .then((response) => {
        console.log(response.data)
        json2csvAsync(JSON.parse(response.data), {delimiter:{field:';'}}).then((c) => { setResultCsvFile(c) });
        setIsActive(false);
        setDownloadLoading(true)
      }).catch((error) => {
        console.log(error)
        setIsActive(false)
        alert('There is an error related to the service. Please try again later. If the error persists, contact NLP team.')
      })
  }

  const saveCsvFile = () => {
    const dateNow = new Date();
    const fileName = `target-dominance-result-${dateNow.getDate()}-${dateNow.getMonth() + 1}-${dateNow.getFullYear()}.csv`;
    saveAs(new Blob([resultCsvFile]), fileName);
  }


  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text='Loading...'
    >
      <div>
        <Form
          onSubmit={handleUploadFile}
        >
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          />
          <FormButton style={{ marginBottom: '15px', marginTop: '15px' }} content="Upload csv file" />
        </Form>
        {
          downloadLoading && <Button style={{ color: 'black', marginBottom: '15px', marginTop: '15px' }} onClick={() => saveCsvFile()}> Download csv result</Button>
        }
        <Form
          onSubmit={handleSubmit}
        >
          <FormTextArea
            label="Target:"
            name="target"
            id="target"
            required
            onChange={e => setEntity(e.target.value)}
            placeholder="Add target here."
            style={{ width: "500px", height: "50px" }}
          />
          <FormTextArea
            label="Article Text:"
            name="articleText"
            id="article-text"
            required
            onChange={e => setArticle(e.target.value)}
            placeholder="Add article text here."
            style={{ width: "500px", height: "250px" }}
          />
          <FormButton content="Submit" />
        </Form>
        <br />
        <Flex gap="gap.small" vAlign="center">
          <span>
            Dominance result: <Label circular>{resultDominance}</Label>
          </span>
        </Flex>
        <br />
        <Header as="h4">Swagger is available
          <a target="_blank" rel="noreferrer" href="https://target-dominance-api-development.9d6318478c9e44febab1.westeurope.aksapp.io/docs">here</a></Header>
        <Text content="Target Dominance API provides two endpoints - target_dominance and target_dominance_csv_file." />
        <br />
        <Text content="target_dominance receives two parameters - the target and the article, target_dominance_csv_file receives one parameter - csv file." />
        <br />
        <br />
        <Text content="Curl call example:" />
        <br />
        <Text content="curl -X 'POST'" />
        <br />
        <Text content=" 'http://127.0.0.1:8000/target_dominance/'" />
        <br />
        <Text content=" -H 'accept: application/json'" />
        <br />
        <Text content=" -H 'Content-Type: application/json'" />
        <br />
        <Text content=" -d '" />
        <br />
        <Text content="   ’text’: ’This is a test’ " />
        <br />
        <Text content=" '" />
      </div>
    </LoadingOverlay>
  )
}

export default TargetDominanceContainer
