import Workbook from 'react-excel-workbook'
import { Button } from '@fluentui/react-northstar'

export const ExcelDownloadComponent = (props) => {
    const nameOfTheFile = 'top_topics_' + props.date + '.xlsx'

    return(
        <Workbook filename={nameOfTheFile} element={
            <Button 
                content="Export Articles"
                style={{display:"inline-block", marginLeft:"5px"}}
            />}>
            {props.data.map(cluster => (
                <Workbook.Sheet data={cluster.articles} name={cluster.title.substring(0, 31)}>
                    <Workbook.Column label="Article" value="value"/>
                </Workbook.Sheet>
            ))} 
        </Workbook>
    )
};
