import { TextArea, Label } from '@fluentui/react-northstar';


const TopTopicsInformationComponent = (props) => {
    return (
        <div>
            <span>
                Number of articles: <Label circular>{props.numberOfArticles}</Label>
            </span>
            <span>
                Noise: <Label circular>{props.noise}</Label>
            </span>
        </div>
    )
}

export default TopTopicsInformationComponent