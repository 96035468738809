import { useState } from "react"
import axios from 'axios'
import { Form, Flex, Label, FormButton, FormTextArea, Header, Text} from '@fluentui/react-northstar'
import LoadingOverlay from 'react-loading-overlay';

const LangIdContainer = () => {
    const [article, setArticle] = useState('')
    const [resultLanguage, setResultLanguage] = useState('N/A')
    const [resultConfidence, setResultConfidence] = useState('N/A')
    const [isActive, setIsActive] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault();
        setIsActive(true);
        
        const postData = {
          "text": article
        }

        axios.post('https://language-id-api-development.9d6318478c9e44febab1.westeurope.aksapp.io/identify/?article_type=broadcast',
        postData,
        {
          headers: {
            'Authorization': 'e440174e-1801-45c9-9101-0363e5c65e35',
            'Content-Type': 'application/json',
        }})
        .then((response) => { 
          setResultLanguage(response.data.language);
          setResultConfidence(response.data.confidence);
          setIsActive(false);
        })
    }

    return (
      <LoadingOverlay
            active={isActive}
            spinner
            text='Loading...'
        >
      <div>
        <Form
          onSubmit={handleSubmit}
        >
          <FormTextArea
            label="Article Text:"
            name="articleText"
            id="article-text"
            required
            onChange={e => setArticle(e.target.value)}
            style={{width:"500px", hight:"500px"}}
            defaultValue="Add article text here."
          />
          <FormButton content="Submit" />
        </Form>
        <br />
        <Flex gap="gap.small" vAlign="center">
          <span>
            Language: <Label circular>{resultLanguage}</Label>
          </span>
          <span>
            Confidence: <Label circular>{resultConfidence}</Label>
          </span>
        </Flex>
        <br />
        <Header as="h4">Swagger is available 
            <a target="_blank" rel="noreferrer" href="https://language-id-api-development.77bbd9f5095a45158b1c.switzerlandnorth.aksapp.io/docs">here</a></Header>
        <Text content="Language Identification API provides one end point - identify."/>
        <br />
        <Text content="It receives two parameters Article Type and the Article text."/>
        <br />
        <br />
        <Text content="Curl call example:"/>
        <br />
        <Text content="curl -X 'POST'"/>
        <br />
        <Text content=" 'http://127.0.0.1:8000/identify/?article_type=broadcast'"/>
        <br />
        <Text content=" -H 'accept: application/json'"/>
        <br />
        <Text content=" -H 'Content-Type: application/json'"/>
        <br />
        <Text content=" -d '"/>
        <br />
        <Text content="   ’text’: ’This is a test’ "/>
        <br />
        <Text content=" '"/>
      </div>
      </LoadingOverlay>
    )
}

export default LangIdContainer
