import { Accordion, Datepicker, Flex, Dropdown } from '@fluentui/react-northstar'
import LoadingOverlay from 'react-loading-overlay';
import { useState } from "react"
import axios from 'axios'
import TitleComponent from './TitleComponent';
import ContentComponent2 from './ContentComponent2';
import PieChartComponent from './PieChartComponent';
import { ExcelDownloadComponent } from './ExcelDownloadComponent';
import TopTopicsInformationComponent from './TopTopicsInfromationComponent';

const TopTopics2Container = () => {
    const [outlet, setOutlet] = useState('')
    const [date, setDate] = useState('')
    const [items, setItems] = useState([])
    const [exportArticlesData, setExportArticlesData] = useState()
    const [isActive, setIsActive] = useState(false)

    const [numberOfArticles, setNumOfArticles] = useState('')
    const [noise, setNoise] = useState('')

    var color = ["#F55302",
        "#F29325",
        "#EDEBE9",
        "#364F67",
        "#4B4B4B",
        "#00203D",
        "#000000",
        "#AF620A",
        "#854A07",
        "#EDBB80"]

    const getRandomColor = () => {
        var index = Math.floor(Math.random() * color.length);
        var pickedColor = color[index];
        color.splice(index, 1);
        return pickedColor;
    }

    const availableArticles = [
        'All articles',
        'Popular outlets'
    ]

    const customFormatter = date => {
        const day = `${date.getDate()}`
        const month = `${date.getMonth() + 1}`
        const year = date.getFullYear()
        return `${day}-${month}-${year}`
    }

    const dateChanged = e => {
        e.preventDefault();

        setIsActive(true);
        setDate(e.target.ariaLabel);

        var responseItems = []

        if (outlet === 'All articles') {
            axios.get("https://top-topics-2-development.9d6318478c9e44febab1.westeurope.aksapp.io/getClusters/?starting_date=" + e.target.ariaLabel,
                {
                    headers: {
                        'Authorization': 'e440174e-1801-45c9-9101-0363e5c65e35',
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) => {

                    setNumOfArticles(response.data.numberOfArticles)
                    setNoise(response.data.noise)


                    response.data.clusteringResult['clusters'].slice(0, 10).forEach(cluster => {
                        var itemColor = getRandomColor()
                        var index = response.data.clusteringResult['clusters'].indexOf(cluster);
                        var item = {
                            key: "Cluster" + index,
                            title: (<TitleComponent cluster={cluster} index={index} itemColor={itemColor} />),
                            value: parseInt(cluster.size),
                            content: (
                                <ContentComponent2 cluster={cluster} />
                            ),
                            color: itemColor
                        };

                        responseItems.push(item);
                    });
                    setExportArticlesData(response.data.clusteringExportData['data']);
                    setIsActive(false);
                    setItems(responseItems);

                })
        } else if (outlet === 'Popular outlets') {
            axios.get("https://top-topics-2-development.9d6318478c9e44febab1.westeurope.aksapp.io/getOutletClusters/?starting_date=" + e.target.ariaLabel,
                {
                    headers: {
                        'Authorization': 'e440174e-1801-45c9-9101-0363e5c65e35',
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) => {

                    setNumOfArticles(response.data.numberOfArticles)
                    setNoise(response.data.noise)


                    response.data.clusteringResult['clusters'].slice(0, 10).forEach(cluster => {
                        var itemColor = getRandomColor()
                        var index = response.data.clusteringResult['clusters'].indexOf(cluster);
                        var item = {
                            key: "Cluster" + index,
                            title: (<TitleComponent cluster={cluster} index={index} itemColor={itemColor} />),
                            value: parseInt(cluster.size),
                            content: (
                                <ContentComponent2 cluster={cluster} />
                            ),
                            color: itemColor
                        };

                        responseItems.push(item);
                    });
                    setExportArticlesData(response.data.clusteringExportData['data']);
                    setIsActive(false);
                    setItems(responseItems);

                })
        }
    }

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading...'
        >
            <Flex gap="gap.large">
                <div style={{ minWidth: "800px" }}>
                    <Dropdown
                        items={availableArticles}
                        placeholder="Please choose articles"
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: item => setOutlet(item)
                        }}
                    />
                    <div style={{ marginTop: '20px' }} /> {/* Blank space with margin */}
                    <Datepicker
                        onDateChange={e => dateChanged(e)}
                        formatMonthDayYear={customFormatter}
                        style={{ display: "inline-block" }}
                    />
                    {exportArticlesData && <TopTopicsInformationComponent numberOfArticles={numberOfArticles} noise={noise} />}
                    {exportArticlesData && <ExcelDownloadComponent data={exportArticlesData} date={date} />}
                    <Accordion
                        panels={items}
                        exclusive
                        style={{ margnTop: "10px" }}
                    />
                </div>
                <div style={{ position: "relative" }}>
                    <PieChartComponent items={items} />
                </div>
            </Flex>
        </LoadingOverlay>
    )
}

export default TopTopics2Container
