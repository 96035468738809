import { useState } from "react"
import axios from 'axios'
import { Form, Flex, Label, FormButton, FormTextArea, Header, Text, Button } from '@fluentui/react-northstar'
import LoadingOverlay from 'react-loading-overlay';
import { saveAs } from 'file-saver';
import { json2csvAsync } from 'json-2-csv';


const PressReleaseContainer = () => {
  const [isActive, setIsActive] = useState(false)
  const [articles, setArticles] = useState()
  const [prs, setPrs] = useState()
  const [resultCsvFile, setResultCsvFile] = useState()
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [lowerThreshold, setLowerThreshold] = useState('');
  const [upperThreshold, setUpperThreshold] = useState('');
  const [group, setGroup] = useState('');

  const handleArticleChange = (event) => {
    setArticles(event.target.files[0]);
  };

  const handlePrsChange = (event) => {
    setPrs(event.target.files[0]);
  };

  const handleLowerThreshold = (event) => {
    setLowerThreshold(event.target.value);
  };

  const handleUpperThreshold = (event) => {
    setUpperThreshold(event.target.value);
  };

  const handleGroup = (event) => {
    console.log(event.target.value)
    setGroup(event.target.value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsActive(true);
    const postData = new FormData()

    postData.append('input_articles', articles, articles.name);
    postData.append('input_prs', prs, prs.name);

    console.log(articles.name)

    axios.post(`https://codiertool-semantic-similarity-development.9d6318478c9e44febab1.westeurope.aksapp.io/pr_detection_client_specific/?lower_threshold=${lowerThreshold}&upper_threshold=${upperThreshold}&group=${group}`,
      postData,
      {
        headers: {
          'Authorization': 'e440174e-1801-45c9-9101-0363e5c65e35',
          'Content-Type': 'multipart/form-data',
        }
      })
      .then((response) => {
        console.log(response.data)
        json2csvAsync(JSON.parse(response.data), { delimiter: { field: ';' } }).then((c) => { setResultCsvFile(c) });
        setIsActive(false);
        setDownloadLoading(true)
      }).catch((error) => {
        console.log(error)
        setIsActive(false)
        alert('There is an error related to the service. Please try again later. If the error persists, contact NLP team.')
      })
  }


  const saveCsvFile = () => {
    const dateNow = new Date();
    const fileName = `press-release-detection-result-${dateNow.getDate()}-${dateNow.getMonth() + 1}-${dateNow.getFullYear()}.csv`;
    saveAs(new Blob([resultCsvFile]), fileName);
  }


  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text='Loading...'
    >
      <div>
        <Form
          onSubmit={handleSubmit}
        >
          <div className="form-group">
          <label>Csv file with articles</label>
          <input
            type={"file"}
            id={"articles"}
            accept={".csv"}
            onChange={handleArticleChange}
          />
          </div>
          <div className="form-group">
          <label>Csv file with press releases</label>
          <input
            type={"file"}
            id={"prs"}
            accept={".csv"}
            onChange={handlePrsChange}
          />
          </div>
          <div className="form-group">
            <label>Lower threshold</label>
            <input
              type={'text'}
              id={'lowerThreshold'}
              style={{ width: '100px' }}
              onChange={handleLowerThreshold}
            />
          </div>
          <div className="form-group">
            <label>Upper threshold</label>
            <input
              type={'text'}
              id={'upperThreshold'}
              style={{ width: '100px' }}
              onChange={handleUpperThreshold}
            />
          </div>
          <div className="form-group">
          <label>Business sector group</label>
            <select onChange={handleGroup}>
              <option></option>
            <option value="20">General</option>
            <option value="18">Trade (incl. retail)</option>
            <option value="15">Research, science, education</option>
            <option value="12">Mobility, transport and logistics</option>
          </select>
          </div>

      <FormButton style={{ marginBottom: '15px', marginTop: '15px' }} content="PressRelease detection" />
    </Form>
        {
    downloadLoading && <Button style={{ color: 'black', marginBottom: '15px', marginTop: '15px' }} onClick={() => saveCsvFile()}> Download csv result</Button>
  }
        <br />
        <br />
        <Header as="h4">Swagger is available
          <a target="_blank" rel="noreferrer" href="https://codiertool-semantic-similarity-development.9d6318478c9e44febab1.westeurope.aksapp.io/docs">here</a></Header>
        <Text content="PressRelease detection API provides endpoint pr_detection_client_specific and pr_detection endpoint which is used in codiertool." />
        <br />
        <Text content="pr_detection_client_specific receives two files and three parameters - file with press releases, file with articles, parameter for upper threshold, lower threshold and business sector group" />
        <br />
        <br />
        <Text content="Curl call example:" />
        <br />
        <Text content="curl -X 'POST'" />
        <br />
        <Text content=" 'http://127.0.0.1:8000/pr_detection_client_specific/'" />
        <br />
        <Text content=" -H 'accept: application/json'" />
        <br />
        <Text content=" -H 'Content-Type: application/json'" />
        <br />
        <Text content=" -d '" />
        <br />
        <Text content="   ’text’: ’This is a test’ " />
        <br />
        <Text content=" '" />
      </div >
    </LoadingOverlay >
  )
}

export default PressReleaseContainer
